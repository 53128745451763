@import 'stylesheets/fonts';
@import 'stylesheets/vendor/flag-icon-css/sass/flag-icon';
@import 'flatpickr/dist/flatpickr';
@import 'flatpickr/dist/themes/light';
@import '@uppy/core/dist/style';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'highlight.js/scss/github';
// @import 'ion-rangeslider/css/ion.rangeslider';
// @import 'stylesheets/vendor/ion-range-slider/material';
//

pre > code {
  font-size: 0.9rem !important;
}

// uppy file input + tailwindcss
.uppy-FileInput-input {
  display: none;
}

.uppy-FileInput-container {
  button {
    @apply ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }
}

span.help-block {
  @apply mt-2 text-sm text-red-600;
}

// .field_with_errors {
//   input {
//     @apply block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md;
//   }
// }

.form-group.has-error {
  input {
    @apply block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md;
  }
}

span.error {
  @apply mt-2 text-sm text-red-600 block;
}

// notification animations
@keyframes notification-countdown {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}
